import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Lingue } from './oggetti/lingue.model';
import  * as Url from './oggetti/url.model';
import { Trad } from './oggetti/trad.model';
import { Countries } from './oggetti/countries.model';

@Injectable({ providedIn: 'root' })


export class TraduzioniService {
  traduzione: any;
  listaLingue: Lingue[];


  constructor(
    private httpClient: HttpClient,
  ) {  }



  //  *****************************************************************
  //  RECUPERO LA LISTA DELLE LINGUE DAL WEB:
  //
  //  getWebTraduzioni(): scarica dal web tutte la traduzione di tutta la App
  //  getTraduzione: restituisce la traduzione che ho in memoria
  //
  //  *******************************************************************

  getWebTraduzioni(language, country, permessi): Observable<Trad[]> {
    console.log('SERVICE --> getTraduzioni Start: ' + language + '; COUNTRY: '+ country);
    let params = new HttpParams();
    params = params.append('language', language);
    params = params.append('country', country);
    params = params.append('permessi', permessi);
    return this.httpClient.get<Trad[]>( Url.urlWebTraduzioni , { params } )
      .pipe(tap ( traduzione => {
        this.traduzione = traduzione;
        console.log('SERVICE --> getTraduzioni Stop: ricevuto traduzione web ' + permessi);
        }
    ));
  }
  getTraduzione(){return this.traduzione; }




  //  *****************************************************************
  //  RECUPERO LA LISTA DELLE LINGUE DAL WEB:
  //
  //  getWebLinguaList(): scarica dal web tutte la lista delle lingue disponibili
  //  getListaLingue: restituisce la lista delle lingue che ho in memoria
  //
  //  *******************************************************************

  getWebLinguaList(): Observable<Lingue[]>  {
    return this.httpClient.get<Lingue[]>(Url.urlWebLinguaList)
      .pipe(tap (_ => this.listaLingue = _ )
      );
  }
  getListaLingue()  {return this.listaLingue;  }



getCountries(): Observable<Countries[]> {
  return this.httpClient.get<Countries[]>(Url.urlCountries)
    .pipe( tap (_ => console.log( _ )));
}

}
