import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { WebService } from './web.service';

import { Cart } from './oggetti/cart.model';
import  * as Url from './oggetti/url.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private myUser: string;
  private myLanguage: string;
  private myCountry: string;
  private myToken: string;

  constructor(
    private httpClient: HttpClient,
    private webService: WebService,
  ) {
    this.myUser = this.webService.myUser;
    this.myLanguage = this.webService.credenziali.language;
    this.myCountry = this.webService.credenziali.country;
    this.myToken = this.webService.credenziali.token;
  }


  getCart(): Observable<Cart[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    console.log(this.myUser);
    return this.httpClient.get<Cart[]>( Url.urlGetCartNumber, { params })
      .pipe( tap ( carrello => console.log('SERVICE --> Ricevuto Carrello'))
      );
  }

  addCart(item, accessori, desc, categoria, sottocategoria,
      qty, price, netPrice, accPrice, accNetPrice, isPromo, isStock, isSecondaScelta, matricola, promoId): Observable<Cart[]> {
        console.log('add cart');
        let params = new HttpParams();
        params = params.append('user', this.myUser);
        params = params.append('item', item);
        params = params.append('accessori', accessori);
        params = params.append('desc', desc);
        params = params.append('categoria', categoria);
        params = params.append('sottocategoria', sottocategoria);
        params = params.append('qnt', qty);
        params = params.append('price', price);
        params = params.append('netPrice', netPrice);
        params = params.append('accPrice', accPrice);
        params = params.append('accNetPrice', accNetPrice);
        params = params.append('isPromo', isPromo);
        params = params.append('isStock', isStock);
        params = params.append('isSecondaScelta', isSecondaScelta);
        params = params.append('matricola', matricola);
        params = params.append('promoId', promoId);
    return this.httpClient.get<Cart[]>(Url.urlAddCart , { params })
        .pipe(tap (_ => console.log( 'arrivato' )));
  }

  deleteCart(id): Observable<Cart[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('id', id);
    return this.httpClient.get<Cart[]>(Url.urlDeleteCart , { params } )
    .pipe(tap (_ => console.log( _ )));
}


editCart(id,qnt,price,netPrice): Observable<Cart[]> {
  let params = new HttpParams();
  params = params.append('user', this.myUser);
  params = params.append('id', id);
  params = params.append('qnt', qnt);
  params = params.append('price', price);
  params = params.append('netPrice', netPrice);
  return this.httpClient.get<Cart[]>( Url.urlEditCart, { params })
  .pipe(tap (_ => console.log( _ )));
}

processaOrdine(note,nome,indirizzo,citta,cap,country,sumPrice,sumNetPrice,currency): Observable<Cart[]> {
  let params = new HttpParams();
  params = params.append('user', this.myUser);
  params = params.append('note', note);
  params = params.append('nome', nome);
  params = params.append('indirizzo', indirizzo);
  params = params.append('citta', citta);
  params = params.append('cap', cap);
  params = params.append('country', country);
  params = params.append('sumPrice', sumPrice);
  params = params.append('sumNetPrice', sumNetPrice);
  params = params.append('currency', currency);
  return this.httpClient.get<Cart[]>(Url.urlProcessaCart , { params })
    .pipe( tap (_ => console.log( _ )));
}






}
