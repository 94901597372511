import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { WebService } from './web.service';
import { Doc } from './oggetti/doc.model';
import  * as Url from './oggetti/url.model';

@Injectable({
  providedIn: 'root'
})

export class DocService {

  public myLanguage: string;
  private myUser: string;
  private myCountry: string;
  private myToken: string;

  constructor(private httpClient: HttpClient, private webService: WebService )
    {
      this.myUser = this.webService.myUser;
      this.myLanguage = this.webService.credenziali.language;
      this.myCountry = this.webService.credenziali.country;
      this.myToken = this.webService.credenziali.token;
    }

   getDocService(item,acc,tipo): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('item', item);
    params = params.append('acc', acc);
    params = params.append('tipo', tipo);
    params = params.append('lingua', this.myLanguage);
    console.log('Richiesta documentazione tecnica');
    return this.httpClient.get<Doc[]>(Url.urlDocumentListService, { params })
      .pipe( tap (_ => console.log( _ )));
  }

  getBomService(item, matricola, lingua): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('item', item);
    params = params.append('matricola', matricola);
    params = params.append('lingua', this.myLanguage);

    return this.httpClient.get<Doc[]> (Url.urlGetBomService, { params } )
    .pipe( tap ( _ => console.log(_)));
    /*
    return this.httpClient.get<Doc[]> (Url.urlGetBomService +'?item='+item+'&matricola='+matricola+'&lingua=IT', {} )
          .pipe( tap ( _ => console.log(_)));
    */
  }

  getPdfSpc(item): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('lingua', this.myLanguage);
    params = params.append('country', this.myCountry);
    params = params.append('item', item);
    return this.httpClient.get<Doc[]> ( Url.urlGetPdfSpec, { params })
      .pipe(tap (_ => console.log( _ )));
  }

  getDocumenti(item): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('item', item);
    params = params.append('lingua', this.myLanguage);
    console.log(Url.urlGetDocumenti+'?item='+item);
    return this.httpClient.get<Doc[]>(Url.urlGetDocumenti , { params })
      .pipe(tap (_ => console.log( _ )));
  }


  getNews(): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    return this.httpClient.get<Doc[]>( Url.urlGetNews , { params } )
      .pipe( tap (_ => console.log( _ )) );
  }

  sendPdf(pdfUrl,sendTo): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('pdf_file', pdfUrl);
    params = params.append('send_to', sendTo);
    console.log('Send???');
    return this.httpClient.get<Doc[]>( Url.urlViewerShare , { params } )
      .pipe( tap ( _ => console.log( _ )) );
  }

  getPdfServiceLog(mac,matricola): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('lingua', this.myLanguage);
    params = params.append('mac', mac);
    params = params.append('matricola', matricola);
    return this.httpClient.get<Doc[]> ( Url.urlGetPdfServiceLog, { params })
      .pipe(tap (_ => console.log( _ )));
  }

  getPdf24hLog(mac,matricola): Observable<Doc[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('lingua', this.myLanguage);
    params = params.append('mac', mac);
    params = params.append('matricola', matricola);
    return this.httpClient.get<Doc[]> ( Url.urlGetPdf24hLog, { params })
      .pipe(tap (_ => console.log( _ )));
  }



}
