import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { WebService } from './web.service';

import { Promo } from './oggetti/promo.model';
import { VersionItem } from './oggetti/versionItem.model';
import { Matricola } from './oggetti/matricola.model';
import { Mac } from './oggetti/mac.model';
import { Conf } from './oggetti/conf.model';
import  * as Url from './oggetti/url.model';


@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private myUser: string;
  private myLanguage: string;
  private myCountry: string;
  private myToken: string;
  private macs: Mac[];

  constructor(
    private httpClient: HttpClient,
    private webService: WebService,
    ) {
      this.myUser = this.webService.myUser;
      this.myLanguage = this.webService.credenziali.language;
      this.myCountry = this.webService.credenziali.country;
      this.myToken = this.webService.credenziali.token;
   }


  getStock(matricola, ricerca): Observable<Promo[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    params = params.append('ricerca', ricerca);
    return this.httpClient.get<Promo[]>(Url.urlGetStock , { params })
      .pipe(tap( users => console.log('Users retrieved!')));
  }

  getPromoDetails(id,item,matricola): Observable<Promo[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('promoId', id);
    params = params.append('itemId', item);
    params = params.append('matricola', matricola);
    return this.httpClient.get<Promo[]>(Url.urlGetItemPromoList, { params })
      .pipe(tap ( _ => console.log( _ )));
  }

  getPromoList(): Observable<Promo[]> {
    console.log('parto qui: ' + this.myUser);
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    return this.httpClient.get<Promo[]>(Url.urlGetItemPromoList , { params })
      .pipe(tap( users => console.log('Users retrieved!')));
  }

  getItems(tipoPagina,item, limit, pag): Observable<Promo[]> {
    console.log ('Questo è items: ' + item);
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('tipoPagina', tipoPagina);
    params = params.append('item', item);
    params = params.append('limit', limit);
    params = params.append('pag', pag);
    return this.httpClient.get<Promo[]>(Url.urlGetItems, { params })
      .pipe(tap( users => console.log(users)));
  }

  getDishwashers(tipoPagina,item, limit, ricerca): Observable<Promo[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('tipoPagina', tipoPagina);
    params = params.append('item', item);
    params = params.append('limit', limit);
    params = params.append('ricerca', ricerca);
    return this.httpClient.get<Promo[]>(Url.urlGetDishwasher, { params })
      .pipe(tap( users => console.log('Items retrieved!')));
  }

  getItemDiba(matricola,search,modello, matricolaDa, matricolaA, limit, pag): Observable<Promo[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    params = params.append('search', search);
    params = params.append('modello', modello);
    params = params.append('matricolaDa', matricolaDa);
    params = params.append('matricolaA', matricolaA);
    params = params.append('limit', limit);
    params = params.append('pag', pag);
    console.log('parametri diba ' + matricola + ' ' + modello + ' ' + matricolaDa + ' ' + matricolaA);
    return this.httpClient.get<Promo[]>(Url.urlDiba, { params })
      .pipe(tap( ver => console.log(ver)));
  }

  getItemConfig(mmL,mmP,mmH,sottocategoria,cesto): Observable<Conf[]> {
    console.log('ok conf service');
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('mmL', mmL);
    params = params.append('mmP', mmP);
    params = params.append('mmH', mmH);
    params = params.append('sottocategoria', sottocategoria);
    params = params.append('cesto', cesto);
    return this.httpClient.get<Conf[]>( Url.urlGetConfig, { params })
      .pipe( tap (_ => 'configuratore Ok!!!'));
  }

  getVersionItem(search): Observable<VersionItem[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('search', search);
    return this.httpClient.get<VersionItem[]>(Url.urlVersionItem, { params })
      .pipe(tap( ver => console.log(ver)));
  }

  getItemDaMatricola(matricola): Observable<Matricola[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    console.log(matricola);
    return this.httpClient.get<Matricola[]>(Url.urlMatricolaModello, { params })
      .pipe(tap( ver => console.log(ver)));
  }

  //Ritorna la lista dei mac a cui si è associato l'utente
  getMac(matricola): Observable<Mac[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    return this.httpClient.get<Mac[]>(Url.urlMac, { params })
      .pipe(tap( mac => {this.macs = mac; }));
  }

  //Rimuove dalla lista utente la matricola associata
  deviceRemove(matricola): Observable<Mac[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    return this.httpClient.get<Mac[]>(Url.urlDeviceRemove, { params })
      .pipe(tap( mac => { this.macs = mac; }));
  }

   //Rimuove dalla lista utente la matricola associata
   deviceHide(matricola, name): Observable<Mac[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    params = params.append('name', name);
    return this.httpClient.get<Mac[]>(Url.urlDeviceHide, { params })
      .pipe(tap( mac => { this.macs = mac; }));
  }

  //Aggiunge dalla lista utente la matricola associata
  deviceAdd(matricola, alias): Observable<Mac[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    params = params.append('alias', alias);
    return this.httpClient.get<Mac[]>(Url.urlDeviceAdd, { params })
      .pipe( tap( mac => { this.macs = mac; }));
  }

  //Aggiunge dalla lista utente la matricola associata
  deviceShare(matricola, share): Observable<Mac[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    params = params.append('share', share);
    return this.httpClient.get<Mac[]>(Url.urlDeviceShare, { params })
      .pipe( tap( mac => { this.macs = mac; }));
  }

  //Modifica dalla lista utente le informazioni alla matricola associata
  deviceEdit(matricola, alias, macad, psw): Observable<Mac[]> {
    let params = new HttpParams();
    params = params.append('user', this.myUser);
    params = params.append('matricola', matricola);
    params = params.append('alias', alias);
    params = params.append('mac', macad);
    params = params.append('psw', psw);
    return this.httpClient.get<Mac[]>(Url.urlDeviceEdit, { params })
      .pipe(tap( mac => { this.macs = mac; }));
  }

}
